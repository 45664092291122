import { Drawer as MantineDrawer } from "@mantine/core";
import XMark from "@impulso/common/Icons/XMark";
import { NotificationDrawer } from "src/modules/notifications/NotificationDrawer";
import { CategoryDrawer, CategoryDrawerProps } from "src/modules/dashboard/drawers/CategoryDrawer";

export interface DrawerProps {
  opened: boolean;
  onClose: ()=>void;
}

export interface NotificationDrawerProps {
  id: "notification";
}

export interface CategoryDrawerParameters {
  id: "category";
  title?: string;
  props: CategoryDrawerProps;
}

export type DrawerType = NotificationDrawerProps | CategoryDrawerParameters;

export function Drawer(props: DrawerProps & {shownDrawer: DrawerType}) {
  const zIndex = 10001; //intercom is 10000 so we need to be after it

  return <MantineDrawer
    opened={props.opened}
    onClose={props.onClose}
    position="right"
    title={props.shownDrawer.id === "category" ? props.shownDrawer.title : ""}
    overlayProps={{opacity: 0}}
    withCloseButton={false}
    styles={{root: {zIndex, position: "relative"}, body: {padding: 0, margin: 0}}}
    size="515px"
    trapFocus = {false}
  >
    <div className="h-screen flex flex-col overflow-y-hidden p-6 pr-9">
      <Header title={getTitle(props.shownDrawer)} onClose={props.onClose}/>
      <DrawerComponent drawerType={props.shownDrawer} onClose={props.onClose}/>
    </div>
  </MantineDrawer>;
}

interface HeaderProps {
  onClose: ()=>void;
  title: string;
}

function Header({onClose, title}: HeaderProps){
  return <div className="flex flex-shrink justify-between">
    <span className="font-semibold">
      {title}
    </span>
    <div onClick={() => onClose()} className="flex cursor-pointer">
      <XMark/>
    </div>
  </div>;
}

function DrawerComponent(props: {drawerType: DrawerType, onClose: ()=>void}) {
  switch(props.drawerType.id) {
    case "notification":
      return <NotificationDrawer onClose={props.onClose}/>;
    case "category":
      return <CategoryDrawer {...props.drawerType.props} />;
  }
}

  function getTitle(shownDrawer: DrawerType) : string {
    switch(shownDrawer.id) {
      case "notification":
        return "Notifications";
      case "category":
        return "";
    }
  }
